
import React, { useEffect, useContext, useState, useRef, lazy, Suspense } from "react"
import { graphql } from 'gatsby'
import Box from '@mui/material/Box'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import '../components/layout'
import { AuthContext } from "../context/general"
import Layout from "../components/layout"
import { fetchTransactions, fetchNotifications, fetchCredits, greet } from '../helpers/fetchers'
import Seo from "../components/seo"
import MainImage from '../pagesComponents/homepage/mainImage'
import MainFilter from '../pagesComponents/homepage/mainFilter'
import ConferencesSection from '../pagesComponents/homepage/conferencesSection'
import CyclesSection from '../pagesComponents/homepage/cyclesSection'
import SecondPhoto from '../pagesComponents/homepage/secondPhoto'
import ReplaySection from '../pagesComponents/homepage/replaySection'
import { Location } from '@reach/router'
import queryString from 'query-string'
import PropTypes from "prop-types"
import scrollTo from 'gatsby-plugin-smoothscroll'
import publicIp from 'public-ip'
import {DEBUG} from '../buildSpecs'



import FrontOfficeTheme, { sxStyle } from '../style/globalTheme'

// import Calendar from './components/homepage/calendar'

 const Calendar = lazy(() => import('../pagesComponents/homepage/calendar'))


const title = 'conférences, webinars, cours en ligne pour tous avec Pekoia'
const description = "Pekoia est une université digitale ouverte à tous, qui permet d’organiser des rencontres (conférences à distance, webinars, cours en ligne) entre des conférenciers et tous ceux qui veulent apprendre sur leur temps libre (histoire de l’art, développement personnel, histoire, sciences ..."




const withLocation = ComponentToWrap => props => (
  <Location>
      {({ location, navigate }) => (
          <ComponentToWrap
              {...props}
              location={location}
              navigate={navigate}
              search={location.search ? queryString.parse(location.search) : {}}
          />
      )}
  </Location>
)


const CustomQueryStringComponent = ({ search, data }) => {
  return (<IndexPage search={search} data={data} />)
}
CustomQueryStringComponent.propTypes = {
  search: PropTypes.object,
}

const IndexPage = ({ data, search }) => {



  if (DEBUG){

    if (DEBUG == 1)
      return (<Box>DEBUG MODE</Box>)


  }
 





    const InsideWrapper = ({data}) =>{

       

      const { authState, authActions } = useContext(AuthContext)
      const [cognitoId, setCognitoId] = useState(null)
      const [isMounted, setIsMounted] = useState(false)
      const [hasNotifications, setHasNotifications] = useState(false)
      const [ssrTest, setSsrTest] = useState(false)

      useEffect(() => {

    

        if (!isMounted)
          setIsMounted(true)
    
    
        if (authState?.user && authState.user.cognitoId != cognitoId) {
    
    
    
          
    
    
          const cognitoId = authState.user.cognitoId
          fetchTransactions(cognitoId, authActions.setTransactions, authActions.setCards, authActions.set_HasNeverBought, authActions.appendTransactions, authActions.setCredits, authActions.setReplays, authActions.appendReplays)
          fetchCredits(cognitoId, authActions.setCredits)
          setCognitoId(authState.user.cognitoId)
        }
        if (authState?.user?.id && !hasNotifications) {
    
          const clientId = authState.user.id
          fetchNotifications(clientId, authActions.setNotifications)
          setHasNotifications(true)
    
        }
    
    
      }, [authState])
    
    
      
    
      useEffect(() => {
    
        let timer
    
        if (search.position == 'newsletter' )
        {
          const timer = setTimeout(() => {
            console.log('@REF')
            console.log(search)
            window.scrollTo(0,2300)
           }, 50);
         }
    
         
         return () => clearTimeout(timer);
        }
    
        
    
       ,[]);

       

      const newsletterRef = useRef(null)
      const isSSR = typeof window === "undefined"


     

      if (!isMounted)
        return null


      return(<Layout data={data} ssrTest={ssrTest} setSsrTest={setSsrTest}>
        <Seo title={data.markdownRemark.frontmatter.title} description={description} image={data.pageImage.gatsbyImageData.images.fallback.src} />
      
        <Box >
          
          <MainImage data={data} />
        </Box>
        <Box  sx={sxStyle} marginTop="60px" >
          <MainFilter data={data} isMounted={isMounted} ssrTest={ssrTest} />
         
          <ConferencesSection data={data} isMounted={isMounted} />
        </Box>
        <Box sx={sxStyle}>
         
            <CyclesSection data={data} />
            <ReplaySection data={data} />
          <SecondPhoto newsletterRef={newsletterRef} data={data}/>
        

         
          
  
          
        </Box>
  
  
        <Box sx={sxStyle} marginTop="80px" >
          {!isSSR && <Suspense fallback={<Box color="white">Loading...</Box>}>
            
            <Calendar data={data} />
          </Suspense>}
  
        </Box>
      </Layout>)
    }


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FrontOfficeTheme}>
        <InsideWrapper data={data}/>
      </ThemeProvider>
    </StyledEngineProvider>
  );


}

export default withLocation(CustomQueryStringComponent)







export const query = graphql`
 query  ($slug: String!){

  phoneImage: imageSharp(original: {src: {regex: "/phone/"}}) {
    gatsbyImageData(
      height: 1000
      quality: 95
      placeholder: BLURRED
      transformOptions: {cropFocus: ATTENTION}
      )
  }

     pageImage: imageSharp(original: {src: {regex: $slug}}) {
     gatsbyImageData(
       width: 1200
       placeholder: BLURRED
     )
   }

      markdownRemark (fields: {slug: {eq: $slug}})  {
     frontmatter {
       slug
       title
       key
     }

   }

  ogImage: imageSharp(original: {src: {regex: "/main_og_image/"}}) {
    gatsbyImageData(
      width: 1000
      height: 630
      quality: 80
      placeholder: BLURRED
      transformOptions: {cropFocus: ATTENTION}
      )
  }

  replays: allDbEvent(filter: {replayStatus: {eq: "AVAILABLE"}}) {
    edges {
      node {
        categories
        categoriesCode
        categoriesCode
        date
        replayStatus
        replayCategory
        title1
        title2
        imageKey
        imageETag500
        fields {
          slug
        }
        children {
          ... on DBFormateur {
            nom
            prenom
            imageId
            children {
              ... on ImageSharp {
                id
                gatsbyImageData
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }

  mainImage: imageSharp(original: {src: {regex: "/fo_wall/"}}) {
    gatsbyImageData
  }  

  pageImages:  allImageSharp(
    filter: {original: {src: {regex: "/photo/"}}}
  ) {
    edges {
      node {
        gatsbyImageData(
          width: 800
          quality: 90
          placeholder: BLURRED
          transformOptions: {cropFocus: ATTENTION}
          )
      }
    }
  }




  portraits : allImageSharp(filter: {original: {src: {regex: "/portrait/"}}}) {
    edges {
      node {
        
        original {
          src
        }
        
      }
    }
  }
  site {
    buildTime
  }


  site {
      siteMetadata {
        title
        description
        author
        image
        url
      }
    }
  





  markdowns: allMarkdownRemark (filter: {frontmatter: {color: {ne: ""}}}){
    nodes {
      html
      frontmatter {
        title
        key
        type
        position
        color
        slug
      }
    }
  }

  cycles: allDbCycle (filter: {status: {eq: "PUBLISHED"}}) {
    edges {
      node {
        title
        status
        imageKey
        imageETag500
        children {
          ... on ImageSharp {
            original{
              src
            }

        
      
          }
    
        }
        formateur: children {
          ... on DBFormateur {
            nom
            prenom
            ... on DBFormateur {
              id
            nom
          prenom
          sanboxLemonwayId
          hashList
          intro
          fields{
              formateurId
              slug
            }
          children {
              ...on ImageSharp {
              gatsbyImageData
                original{
  
              src}
              }
            }
          }

        
      
          }
    
        }
        events: children {

          ...on DBEvent {
          title1
          title2
          date
          }
        }
        fields{
          cycleId
          slug
        }
      }
    }
  }





  events: allDbEvent
  {
      edges {
        node {
                  title1
                  title2
                  format
                  outil
                  imageKey
                  imageETag500
                  categoriesCode
                  formateurId
                  nbMax
                  nbReserved
                  imageETag300
                  attendingNb
                  date
                  state
                  format
                  children {
                    ... on DBFormateur {
                      nom
                      prenom
                      state
                      imageId
                
                    }
                    ... on ImageSharp {
                      original{
                        src
                      }
                
                
                    }
              
                  }
                  fields
                    {
                      eventId
                      slug
                    }
						}
      		}
  }
 }
`




